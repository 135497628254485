<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-column pt-1"
      >
        <span class="text-muted caption font-italic">Paso 3/3</span>
        <span class="text-h4 font-weight-bold primary--text mt-n1">Confirmar</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-column"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Cliente
        </span>
        <span
          class="font-weight-bold primary--text mt-n1"
          v-text="`${info.cli_des} (${info.co_cli})`"
        />
      </v-col>
      <v-col cols="12" class="py-0"><v-divider /></v-col>
      <v-col
        cols="12"
        sm="6"
        md="8"
        class="d-flex flex-column"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Facturas Asociadas
        </span>
        <div>
          <v-chip
            v-for="(f,index) in info.fact"
            :key="info.fact+'-'+index"
            small
            label
            color="icono"
            dark
            class="mr-1"
            v-text="f"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="d-flex flex-column"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Fecha
        </span>
        <span class="font-weight-bold primary--text mt-n1">{{ info.fecha | smartDate }}</span>
      </v-col>
      <v-col cols="12" class="py-0"><v-divider /></v-col>
      <v-col
        cols="12"
        class="d-flex flex-column"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Tipo de Transacción
        </span>
        <span class="font-weight-bold primary--text mt-n1 text-capitalize" v-text="`${info.metodo_pago} - ${info.moneda}`" />
      </v-col>
      <v-col cols="12" class="py-0"><v-divider /></v-col>
      <template v-if="info.metodo_pago === 'transferencia'">
        <v-col
          cols="12"
          class="d-flex flex-column"
        >
          <span class="caption blue-grey--text text--darken-3 mb-1">
            Banco
          </span>
          <span
            class="font-weight-bold primary--text mt-n1"
            v-text="`${info.banco_des} (${info.cod_banco})`"
          />
        </v-col>
        <v-col cols="12" class="py-0"><v-divider /></v-col>
        <v-col
          cols="6"
          class="d-flex flex-column"
        >
          <span class="caption blue-grey--text text--darken-3 mb-1">
            Referencia Bancaria
          </span>
          <span class="font-weight-bold primary--text mt-n1" v-text="info.ref" />
        </v-col>
      </template>
      <v-col
        v-if="info.moneda === 'bolivares'"
        cols="6"
        class="d-flex flex-column"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Tasa del día
        </span>
        <span class="font-weight-bold primary--text mt-n1" v-text="'Bs. ' + info.tasa" />
      </v-col>
      <v-col cols="12" class="py-0"><v-divider /></v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-column my-3"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Observación
        </span>
        <span class="font-weight-bold primary--text mt-n1" v-text="info.observacion" />
      </v-col>
    </v-row>
    <v-row>               
      <v-col
        cols="12"
        class="d-flex flex-column align-end justify-center blue-grey lighten-5"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Monto Dolares
        </span>
        <span class="font-weight-bold primary--text text-h4 mt-n1">
          <template v-if="info.moneda === 'dolares'">
            {{ info.monto | FormatCurrency }} $
          </template>
          <template v-if="info.moneda === 'bolivares'">
            {{ info.montoDolares | FormatCurrency }} $
          </template>
        </span>
      </v-col>
      <v-col
        cols="12"
        class="d-flex flex-column align-end justify-center grey lighten-5 mb-2"
      >
        <span class="caption blue-grey--text text--darken-3 mb-1">
          Monto Bolívares
        </span>
        <span class="font-weight-bold primary--text text-h4 mt-n1">
          {{ info.monto_bs | FormatCurrency }} Bs.
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ConfirmacionInfo',
  props:{
    info:{
      type: Object,
      default: () => ({})
    },
  },
}
</script>